import { createApp } from 'vue'

// App

import App from './App.vue'
const app = createApp(App)

// Router

import router from './router'
app.use(router)

// router.beforeEach((to) => {
//   const host = window.location.host
//   if (host != 'emblem.games' && host != 'localhost:8080') {
//     window.location.href = 'https://tov.games/'
//   }

//   // document.title = `Tov.games — We Make Tov Games`
// })

// Render

app.mount('#app')
